import React from "react";
import {
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  isThisMonth,
  isThisYear,
} from "date-fns";
import { Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import deLocale from "date-fns/locale/de";

const FormattedDate = ({ date, text }) => {
  const language = useSelector((state) => state.language);
  const { palette } = useTheme();
  if (!date) return;

  const locale = language === "german" ? deLocale : null;

  const newDate = new Date(date);

  const isWithinLastWeek = (date) => {
    const now = new Date();
    const oneWeekAgo = new Date(now);
    oneWeekAgo.setDate(now.getDate() - 7);

    return date >= oneWeekAgo && date <= now;
  };

  const now = new Date();
  const minutesDiff = differenceInMinutes(now, newDate);
  const hoursDiff = differenceInHours(now, newDate);
  const daysDiff = differenceInDays(now, newDate);

  let result = "";

  if (minutesDiff < 1) {
    result = language === "german" ? "Jetzt Gerade" : "Just now";
  } else if (minutesDiff < 60) {
    result =
      language === "german"
        ? `vor ${minutesDiff} Minute${minutesDiff === 1 ? "" : "n"}`
        : `${minutesDiff} minute${minutesDiff === 1 ? "" : "s"} ago`;
  } else if (hoursDiff < 24) {
    result =
      language === "german"
        ? `vor ${hoursDiff} Stunde${hoursDiff === 1 ? "" : "n"}`
        : `${hoursDiff} hour${hoursDiff === 1 ? "" : "s"} ago`;
  } else if (daysDiff < 7) {
    result =
      language === "german"
        ? `vor ${daysDiff} Tag${daysDiff === 1 ? "" : "en"}`
        : `${daysDiff} day${daysDiff === 1 ? "" : "s"} ago`;
  } else if (isWithinLastWeek(newDate)) {
    result = `${format(newDate, "EEEE", { locale })}`;
  } else if (isThisMonth(newDate)) {
    result = `${format(newDate, "d MMMM", { locale })}`;
  } else if (isThisYear(newDate)) {
    result = `${format(newDate, "d MMMM", { locale })}`;
  } else {
    result = `${format(newDate, "d MMMM yyyy", { locale })}`;
  }

  return (
    <Typography color={palette.neutral.main}>
      {text}
      {result}
    </Typography>
  );
};

export default FormattedDate;
