import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function CropDialog({
  open,
  setOpen,
  images,
  croppingIndex,
  setImages,
  edited = false, // usage in this component; added to identify edited/new images, so that they can be deleted server side
  setImagesToDelete = false,
}) {
  // 16 : 9 crop is still a bit buggy, the anchor points move arounud

  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const imageRef = useRef(null);

  const [isAspectRatioLock, setAspectRatioLock] = useState(true);

  function lockAR(boolean) {
    setAspectRatioLock(!isAspectRatioLock);
    boolean &&
      setCrop({
        unit: "px",
        x: 0,
        y: 0,
        width: 160,
        height: 90,
        aspect: 16 / 9,
      });
  }

  function setAspectRatio(newCrop) {
    // Ensure aspect ratio remains 16:9
    if (newCrop.width && newCrop.height && newCrop.aspect !== 16 / 9) {
      const aspectRatioWidth = newCrop.height * (16 / 9);
      if (newCrop.width !== aspectRatioWidth) {
        const originalWidth = newCrop.width;
        const adjustedWidth = Math.min(originalWidth, aspectRatioWidth);
        const adjustedHeight = (9 / 16) * adjustedWidth;

        // Adjust position when resizing from top left corner
        if (newCrop.x > newCrop.x + originalWidth - adjustedWidth) {
          newCrop.x += originalWidth - adjustedWidth;
        }

        // Adjust position when resizing from bottom left corner
        if (newCrop.y > newCrop.y + newCrop.height - adjustedHeight) {
          newCrop.y += newCrop.height - adjustedHeight;
        }

        // Adjust position when resizing from top right corner
        if (newCrop.y > newCrop.y + newCrop.height - adjustedHeight) {
          newCrop.y += newCrop.height - adjustedHeight;
        }

        newCrop.width = adjustedWidth;
        newCrop.height = adjustedHeight;
      }
    }
    setCrop(newCrop);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const [crop, setCrop] = useState({
    unit: "px",
    x: 0,
    y: 0,
    width: 160,
    height: 90,
    aspect: isAspectRatioLock ? 16 / 9 : undefined,
  });

  useEffect(() => {
    const img = imageRef.current;
    let width;
    let height;

    if (img) {
      width = img.width;
      height = img.height;
    }

    setCrop({
      unit: width ? "px" : "%",
      x: 0,
      y: 0,
      width: width || 100,
      height: height || 100,
      aspect: 16 / 9,
    });
    //setCroppedImage(null);
  }, [open]);

  //const [croppedImage, setCroppedImage] = useState(null); // used for downloading images

  const handleConfirmCrop = () => {
    const img = imageRef.current;

    const width = img.width;
    const height = img.height;

    if (crop.width === width && crop.height === height) return handleClose();

    if (!images[croppingIndex].edited) {
      setImagesToDelete &&
        setImagesToDelete((prevFiles) => [
          ...prevFiles,
          images[croppingIndex].file.name,
        ]);
    }

    const image = new Image();
    image.src = images[croppingIndex].dataURL;

    const scaleX = image.naturalWidth / width;
    const scaleY = image.naturalHeight / height;

    const canvas = document.createElement("canvas");
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX, // x-coordinate in source image
      crop.y * scaleY, // y-coordinate in source image
      crop.width * scaleX, // width in source image
      crop.height * scaleY, // height in source image
      0, // destination "
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const croppedDataURL = canvas.toDataURL("image/jpeg"); // check if works with PNG also check on serverside for cropped images
    //setCroppedImage(croppedDataURL);

    const updatedImages = [...images];
    updatedImages[croppingIndex] = {
      ...updatedImages[croppingIndex],
      dataURL: croppedDataURL,
      cropped: images[croppingIndex].file.name.split(".").pop(),
      edited,
    };

    setImages(updatedImages);
    handleClose();
  };

  /*
  const handleDownload = () => {
    // Create an "a" element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = croppedImage;
    downloadLink.download = "cropped-image.jpg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };*/

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={isNotMobileScreen ? "md" : "lg"}
    >
      <DialogTitle sx={{ fontSize: "1.5rem", paddingBottom: "0" }}>
        Crop Image {croppingIndex + 1}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "0" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAspectRatioLock}
              onChange={(e) => lockAR(e.target.value)}
              name="isAspectRatioLock"
            />
          }
          label="16:9 Aspect Ratio Lock (Recommended)"
          labelPlacement="end"
        />
        {croppingIndex !== null && (
          <div style={{ margin: "1rem 0" }}>
            <ReactCrop
              crop={crop}
              onChange={(c) => {
                setCrop(c);
                isAspectRatioLock && setAspectRatio(c);
              }}
              ruleOfThirds={true}
              unit="%"
            >
              <img
                ref={imageRef}
                src={images[croppingIndex]?.dataURL}
                alt={`uploaded-${croppingIndex}`}
                style={{ width: "100vw" }}
              />
            </ReactCrop>
          </div>
        )}
      </DialogContent>
      <DialogActions sx={{ margin: "12px 24px", padding: "0" }}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          fullWidth
          endIcon={<CloseIcon />}
        >
          Cancel Crop
        </Button>
        <Button
          onClick={() => {
            //handleClose();
            handleConfirmCrop();
          }}
          color="primary"
          variant="contained"
          fullWidth
          endIcon={<CheckIcon />}
        >
          Confirm Crop
        </Button>
        {/*croppedImage && (
          <Button
            onClick={handleDownload}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Download Cropped Image
          </Button>
        )*/}
      </DialogActions>
    </Dialog>
  );
}
