import { styled } from "@mui/material/styles";

const serverUrl =
  "https://windkraft-regional-server-076e810dcef0.herokuapp.com";
// const serverUrl = "http://localhost:5001"; // Development
const amazonUrl = "https://windkraft-regional.s3.amazonaws.com";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function bytesToMB(bytes) {
  const MB = bytes / (1024 * 1024);
  return MB.toFixed(1);
}

const defaultGsapFrom = {
  y: 20,
  opacity: 0,
};

const defaultGsapTo = {
  y: 0,
  opacity: 1,
  stagger: {
    amount: 0.25,
  },
};

function deepEqual(obj1, obj2) {
  // If both objects are not objects, compare them directly
  if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
    return obj1 === obj2;
  }

  // If the number of keys in both objects are different, they're not equal
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check each key-value pair recursively
  for (const key of keys1) {
    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  // If all key-value pairs match, the objects are equal
  return true;
}

const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export {
  serverUrl,
  amazonUrl,
  VisuallyHiddenInput,
  bytesToMB,
  defaultGsapFrom,
  defaultGsapTo,
  deepEqual,
  hexToRGBA,
};
