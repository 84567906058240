import { styled } from "@mui/system";

const StyledDiv = styled("div")(({ width }) => ({
  width,
  fontWeight: "bold",
  cursor: "pointer",
  position: "relative",
  display: "block",
  textTransform: "uppercase",
  letterSpacing: "2px",
  backgroundColor: "transparent",
  textAlign: "center",
  transition: "background-color 0.3s ease-in-out",
}));

const Underline = styled("div")(({ palette, width, left }) => ({
  color: palette.neutral.light,
  transition: "color 0.3s ease",
  "&::before": {
    content: "''",
    position: "absolute",
    display: "block",
    width,
    height: "2px",
    bottom: "-10%",
    left,
    backgroundColor: palette.primary.main,
    transform: "scaleX(0)",
    transition: "transform 0.3s ease",
  },
  "&:hover::before": {
    transform: "scaleX(0.7)",
  },
}));

export { Underline, StyledDiv };
