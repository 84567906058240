import React, { useEffect } from "react";
import TextContent from "components/TextContent";
import { Typography, useMediaQuery } from "@mui/material";
import PageTitle from "components/PageTitle";
import Divider from "@mui/material/Divider";

const News = ({ pageTitle }) => {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  useEffect(() => {
    document.title = `WKR | ${pageTitle}`;
  }, [pageTitle]);

  return (
    <TextContent isNotMobileScreen={isNotMobileScreen}>
      <div style={{ maxWidth: "800px", width: "100%", marginBottom: "5rem" }}>
        <div>
          <PageTitle title={pageTitle} />
        </div>
        <Divider sx={{ m: "1rem 0" }} />
        <Typography>
          Diese Seite ist noch in der Entstehung! Schauen Sie später vorbei.
        </Typography>
      </div>
    </TextContent>
  );
};

export default News;
