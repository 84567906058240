import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import EditIcon from "@mui/icons-material/Edit";

export default function ReportSpeedDial({
  setNewReportInput,
  setEditing,
  setDeleting,
}) {
  return (
    <SpeedDial
      ariaLabel="SpeedDial Toolbox Reports"
      sx={{ position: "fixed", bottom: 16, right: 16 }}
      icon={<HomeRepairServiceIcon />}
    >
      <SpeedDialAction
        icon={<DeleteIcon />}
        tooltipTitle="Löschen"
        onClick={() => {
          setDeleting(true);
          setEditing(false);
        }}
      />
      <SpeedDialAction
        icon={<EditIcon />}
        tooltipTitle="Bearbeiten"
        onClick={() => {
          setEditing(true);
          setDeleting(false);
        }}
      />
      <SpeedDialAction
        icon={<AddCircleSharpIcon />}
        tooltipTitle="Neuer Bericht"
        onClick={() => setNewReportInput(true)}
      />
    </SpeedDial>
  );
}
