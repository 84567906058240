import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Fade,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setCookiePreferences } from "state";

export default function CookieBanner() {
  const [bannerOpen, setBannerOpen] = useState(false);
  const isCookiesAccepted = useSelector((state) => state.isCookiesAccepted);
  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");
  const { palette } = useTheme();
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch();

  useEffect(() => {
    !isCookiesAccepted && setBannerOpen(true);
  }, []); // eslint-disable-line

  const closeBanner = () => {
    setBannerOpen(false);
  };
  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={false} in={bannerOpen}>
        <Paper
          role="dialog"
          aria-modal="false"
          aria-label="Cookie banner"
          square
          variant="outlined"
          tabIndex={-1}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            m: 0,
            p: 2,
            borderWidth: 0,
            borderTopWidth: 1,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            gap={2}
          >
            <Box
              sx={{
                flexShrink: 1,
                alignSelf: { xs: "flex-start", sm: "center" },
                m: isNotMobileScreen ? "0 20vw" : "0",
              }}
            >
              <Typography fontWeight="bold">
                {isGerman
                  ? "Diese Website verwendet Cookies"
                  : "This website uses cookies"}
              </Typography>
              <Typography variant="body2">
                {isGerman ? (
                  <>
                    Diese Website verwendet nur bedingt Cookies, um Ihnen das
                    bestmögliche Online-Erlebnis zu bieten. Bitte lassen Sie uns
                    wissen, dass Sie damit einverstanden sind, indem Sie unten
                    auf die Option "Zustimmen" klicken. Falls Sie mehr über die
                    von uns verwendeten Cookies erfahren und Ihre eigenen
                    Cookie-Einstellungen verwenden möchten, schauen Sie sich
                    bitte unsere{" "}
                    <NavLink
                      style={{ color: palette.primary.main }}
                      to="/cookie-policy"
                    >
                      Cookie-Richtlinie
                    </NavLink>{" "}
                    an.
                  </>
                ) : (
                  <>
                    This website uses limited cookies to give you the best
                    possible online experience. Please let us know that you
                    agree by clicking the "Agree" option below. If you would
                    like to find out more about the cookies we use and use your
                    own cookie settings, please see our{" "}
                    <NavLink
                      style={{ color: palette.primary.main }}
                      to="/cookie-policy"
                    >
                      Cookie Policy
                    </NavLink>
                    .
                  </>
                )}
              </Typography>
            </Box>
            <Stack
              gap={2}
              direction={{
                xs: "row-reverse",
                sm: "row",
              }}
              sx={{
                flexShrink: 0,
                alignSelf: { xs: "flex-end", sm: "center" },
              }}
            >
              <Button
                onClick={() => {
                  closeBanner();
                  dispatch(setCookiePreferences({ isCookiesAccepted: true }));
                }}
                variant="contained"
                sx={{ marginLeft: "auto" }}
              >
                {isGerman ? "Zustimmen" : "Agree"}
              </Button>
              <Button
                onClick={() => {
                  closeBanner();
                  dispatch(setCookiePreferences({ isCookiesAccepted: true }));
                }}
              >
                {isGerman ? "Schließen" : "Close"}
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Fade>
    </TrapFocus>
  );
}
