import React, { useEffect, useRef, useState } from "react";
import { StyledDiv } from "./Underline";
import NavbarButtonLink from "./NavbarButtonLink";
import NavbarButtonDropDownButton from "./NavbarButtonDropDownButton";

export default function NavbarButton({
  type = "default",
  text,
  setDrawerOpen = false,
  width = "auto",
}) {
  const buttonRef = useRef(null);

  // Width detection
  const [textWidth, setTextWidth] = useState(0);
  const [buttonWidth, setButtonWidth] = useState(0);
  const elementRef = useRef(null);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    findUnderlineWidth();

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("load", findUnderlineWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", findUnderlineWidth);
    };
  }, [windowSize, text]); // eslint-disable-line

  function findUnderlineWidth() {
    const measureTextWidth = (text, font, letterSpacing) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      // Add letter-spacing adjustment
      const adjustedWidth = metrics.width + (text.length - 1) * letterSpacing;
      return adjustedWidth;
    };

    if (elementRef.current) {
      const computedStyle = getComputedStyle(elementRef.current);
      const font = `${computedStyle.fontWeight} ${computedStyle.fontSize} ${computedStyle.fontFamily}`;
      const letterSpacing = parseFloat(computedStyle.letterSpacing) || 0;
      const transformedText = text.replace(/_/g, " ").toUpperCase();
      const width = measureTextWidth(transformedText, font, letterSpacing);
      setTextWidth(width);
      setButtonWidth(elementRef.current.offsetWidth);
    }
  }

  let hyperlink = text;
  if (text === "über_uns") hyperlink = "about_us";
  if (text === "kontakt") hyperlink = "contact";
  if (text === "projekte") hyperlink = "projects";
  if (text === "nachrichten") hyperlink = "news";
  if (text === "techn. berichte") hyperlink = "reports";
  if (text === "market data") hyperlink = "market_data";

  const handleClick = () => {
    if (setDrawerOpen !== false) {
      setTimeout(() => {
        setDrawerOpen(false);
      }, 400);
    }
  };

  return (
    <StyledDiv width={width} ref={buttonRef} onClick={(e) => handleClick(e)}>
      {type === "default" && (
        <NavbarButtonLink
          text={text}
          hyperlink={hyperlink}
          textWidth={textWidth}
          buttonWidth={buttonWidth}
          elementRef={elementRef}
        />
      )}
      {type === "dropDown" && (
        <NavbarButtonDropDownButton
          text={text}
          hyperlink={hyperlink}
          textWidth={textWidth}
          buttonWidth={buttonWidth}
          elementRef={elementRef}
        />
      )}
    </StyledDiv>
  );
}
