import { LineDefaultProps } from "@nivo/line";

export const lineDefaults = {
  useMesh: true,

  xFormat: { format: "", enabled: false },
  yFormat: { format: " >-.2f", enabled: true },

  curve: "cardinal", // "monotoneX"

  /* 
  axisTop: {
    enable: false,
    orient: "top",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: 36,
    truncateTickAt: 0,
  },
  axisRight: {
    enable: false,
    orient: "right",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: 0,
    truncateTickAt: 0,
  },
  axisBottom: {
    enable: true,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "transportation",
    legendOffset: 36,
    legendPosition: "middle",
    truncateTickAt: 0,
  },
  axisLeft: {
    enable: true,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "count",
    legendOffset: -40,
    legendPosition: "middle",
    truncateTickAt: 0,
  },
 */
  enableGridX: true,
  enableGridY: true,

  colors: LineDefaultProps.colors,
  lineWidth: 2,

  pointSize: 5,
  pointBorderWidth: 1,
  enablePointLabel: true,
  pointLabel: LineDefaultProps.pointLabel,
  pointLabelYOffset: -12,
  pointBorderColor: { from: "serieColor" },
  /* pointBorderColor: {
    from: "color",
    modifiers: [["darker", 0.3]],
  },*/

  enableArea: true,
  areaOpacity: 0.1,
  areaBlendMode: LineDefaultProps.areaBlendMode,
  areaBaselineValue: LineDefaultProps.areaBaselineValue,

  isInteractive: LineDefaultProps.isInteractive,
  enableSlices: false,
  debugSlices: false,

  enableCrosshair: true,
  enableTouchCrosshair: true,
  crosshairType: "cross",

  legends: [
    {
      anchor: "right",
      direction: "column",
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: "left-to-right",
      itemWidth: 80,
      itemHeight: 20, // Negative to represent this particular legend in the correct order
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: "circle",
      symbolBorderColor: "rgba(0, 0, 0, .5)",
      effects: [
        {
          on: "hover",
          style: {
            itemBackground: "rgba(0, 0, 0, .03)",
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
};
