import { Button, CircularProgress } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export default function PatchButtons({
  cancelFunction,
  patchRequest,
  isLoading,
}) {
  return (
    <div style={{ marginTop: "1rem", display: "flex", gap: "1rem" }}>
      <Button
        variant="outlined"
        onClick={() => {
          cancelFunction();
        }}
        fullWidth
        endIcon={<CloseIcon />}
      >
        Abbruch
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          patchRequest();
        }}
        fullWidth
        endIcon={
          isLoading ? (
            <CircularProgress size={"1.2rem"} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
      >
        Aktualisieren
      </Button>
    </div>
  );
}
