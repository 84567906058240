import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

export default function VideoInput({
  video,
  setVideo,
  content,
  edited = false,
}) {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const [forceUpdate, setForceUpdate] = useState(false);
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      const dataURL = URL.createObjectURL(file);
      setVideo({ file, dataURL, edited });
      setForceUpdate(!forceUpdate);
    }
  };

  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");

  return (
    <>
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        fullWidth
        color={Boolean(video) ? "secondary" : "primary"}
      >
        {Boolean(video)
          ? isGerman
            ? "Video Ersetzen"
            : "Replace Video"
          : isGerman
          ? "Video Hochladen"
          : "Upload Video"}
        <VisuallyHiddenInput
          type="file"
          accept="video/mp4, video/quicktime"
          multiple={false}
          onInput={(e) => handleFileInput(e)}
        />
      </Button>
      {video && (
        <div style={{ marginTop: "1rem" }}>
          <video
            id="videoModule"
            autoPlay={content.isAutoplay}
            controls={content.isControls}
            style={{ width: "100%", borderRadius: "0.25rem" }}
            muted={content.isMuted}
            loop={content.isLoopingVideo}
            src={video.dataURL}
          />
        </div>
      )}
    </>
  );
}
