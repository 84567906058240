import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function DeleteDialog({
  isDeleteModal,
  toggleModal,
  del,
  title,
  dialogContent,
  delButton,
}) {
  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");

  return (
    <Dialog open={isDeleteModal} onClose={toggleModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} color="primary">
          {isGerman ? "Abbruch" : "Cancel"}
        </Button>
        <Button onClick={del} color="error" variant="outlined">
          {delButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
