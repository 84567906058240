// Insert source

export const data = [
  {
    id: "Brutto",
    xName: "Jahre",
    yName: "Preis (€)",
    data: [
      { x: "2007-01", y: 0.2025 },
      { x: "2007-07", y: 0.2105 },
      { x: "2008-01", y: 0.2148 },
      { x: "2008-07", y: 0.2195 },
      { x: "2009-01", y: 0.2282 },
      { x: "2009-07", y: 0.2294 },
      { x: "2010-01", y: 0.2375 },
      { x: "2010-07", y: 0.2438 },
      { x: "2011-01", y: 0.2528 },
      { x: "2011-07", y: 0.2531 },
      { x: "2012-01", y: 0.2595 },
      { x: "2012-07", y: 0.2676 },
      { x: "2013-01", y: 0.2919 },
      { x: "2013-07", y: 0.2921 },
      { x: "2014-01", y: 0.2981 },
      { x: "2014-07", y: 0.2974 },
      { x: "2015-01", y: 0.2951 },
      { x: "2015-07", y: 0.2946 },
      { x: "2016-01", y: 0.2969 },
      { x: "2016-07", y: 0.2977 },
      { x: "2017-01", y: 0.3048 },
      { x: "2017-07", y: 0.3048 },
      { x: "2018-01", y: 0.2987 },
      { x: "2018-07", y: 0.3 },
      { x: "2019-01", y: 0.3088 },
      { x: "2019-07", y: 0.2878 },
      { x: "2020-01", y: 0.3043 },
      { x: "2020-07", y: 0.3006 },
      { x: "2021-01", y: 0.3193 },
      { x: "2021-07", y: 0.3234 },
      { x: "2022-01", y: 0.3279 },
      { x: "2022-07", y: 0.3357 },
      { x: "2023-01", y: 0.4125 },
      { x: "2023-07", y: 0.402 },
    ],
  },
  {
    id: "Netto",
    data: [
      { x: "2007-01", y: 0.1227 },
      { x: "2007-07", y: 0.1279 },
      { x: "2008-01", y: 0.1299 },
      { x: "2008-07", y: 0.1341 },
      { x: "2009-01", y: 0.1401 },
      { x: "2009-07", y: 0.1359 },
      { x: "2010-01", y: 0.1381 },
      { x: "2010-07", y: 0.137 },
      { x: "2011-01", y: 0.1406 },
      { x: "2011-07", y: 0.1395 },
      { x: "2012-01", y: 0.1441 },
      { x: "2012-07", y: 0.1432 },
      { x: "2013-01", y: 0.1493 },
      { x: "2013-07", y: 0.1489 },
      { x: "2014-01", y: 0.1435 },
      { x: "2014-07", y: 0.144 },
      { x: "2015-01", y: 0.1431 },
      { x: "2015-07", y: 0.1427 },
      { x: "2016-01", y: 0.1388 },
      { x: "2016-07", y: 0.1382 },
      { x: "2017-01", y: 0.1389 },
      { x: "2017-07", y: 0.1383 },
      { x: "2018-01", y: 0.1379 },
      { x: "2018-07", y: 0.1378 },
      { x: "2019-01", y: 0.1473 },
      { x: "2019-07", y: 0.1321 },
      { x: "2020-01", y: 0.143 },
      { x: "2020-07", y: 0.1451 },
      { x: "2021-01", y: 0.1562 },
      { x: "2021-07", y: 0.1596 },
      { x: "2022-01", y: 0.1899 },
      { x: "2022-07", y: 0.2333 },
      { x: "2023-01", y: 0.2973 },
      { x: "2023-07", y: 0.2882 },
    ],
  },
];

export const dataAdjustedForInflation = [
  {
    id: "Brutto",
    xName: "Jahre",
    yName: "Preis (€) ohne Inflation",
    data: [
      { x: "2007-01", y: 0.2637 },
      { x: "2007-07", y: 0.2742 },
      { x: "2008-01", y: 0.2728 },
      { x: "2008-07", y: 0.2787 },
      { x: "2009-01", y: 0.2888 },
      { x: "2009-07", y: 0.2904 },
      { x: "2010-01", y: 0.2974 },
      { x: "2010-07", y: 0.3053 },
      { x: "2011-01", y: 0.3099 },
      { x: "2011-07", y: 0.3103 },
      { x: "2012-01", y: 0.3119 },
      { x: "2012-07", y: 0.3216 },
      { x: "2013-01", y: 0.3458 },
      { x: "2013-07", y: 0.3461 },
      { x: "2014-01", y: 0.3496 },
      { x: "2014-07", y: 0.3488 },
      { x: "2015-01", y: 0.3444 },
      { x: "2015-07", y: 0.3438 },
      { x: "2016-01", y: 0.3448 },
      { x: "2016-07", y: 0.3457 },
      { x: "2017-01", y: 0.3487 },
      { x: "2017-07", y: 0.3487 },
      { x: "2018-01", y: 0.3358 },
      { x: "2018-07", y: 0.3373 },
      { x: "2019-01", y: 0.3422 },
      { x: "2019-07", y: 0.319 },
      { x: "2020-01", y: 0.3357 },
      { x: "2020-07", y: 0.3316 },
      { x: "2021-01", y: 0.3415 },
      { x: "2021-07", y: 0.3459 },
      { x: "2022-01", y: 0.3472 },
      { x: "2022-07", y: 0.3555 },
      { x: "2023-01", y: 0.4125 },
      { x: "2023-07", y: 0.402 },
    ],
  },
  {
    id: "Netto",
    data: [
      { x: "2007-01", y: 0.1598 },
      { x: "2007-07", y: 0.1666 },
      { x: "2008-01", y: 0.165 },
      { x: "2008-07", y: 0.1703 },
      { x: "2009-01", y: 0.1773 },
      { x: "2009-07", y: 0.172 },
      { x: "2010-01", y: 0.1729 },
      { x: "2010-07", y: 0.1715 },
      { x: "2011-01", y: 0.1724 },
      { x: "2011-07", y: 0.171 },
      { x: "2012-01", y: 0.1732 },
      { x: "2012-07", y: 0.1721 },
      { x: "2013-01", y: 0.1769 },
      { x: "2013-07", y: 0.1764 },
      { x: "2014-01", y: 0.1683 },
      { x: "2014-07", y: 0.1689 },
      { x: "2015-01", y: 0.167 },
      { x: "2015-07", y: 0.1665 },
      { x: "2016-01", y: 0.1612 },
      { x: "2016-07", y: 0.1605 },
      { x: "2017-01", y: 0.1589 },
      { x: "2017-07", y: 0.1582 },
      { x: "2018-01", y: 0.155 },
      { x: "2018-07", y: 0.1549 },
      { x: "2019-01", y: 0.1632 },
      { x: "2019-07", y: 0.1464 },
      { x: "2020-01", y: 0.1577 },
      { x: "2020-07", y: 0.16 },
      { x: "2021-01", y: 0.1671 },
      { x: "2021-07", y: 0.1707 },
      { x: "2022-01", y: 0.2031 },
      { x: "2022-07", y: 0.2471 },
      { x: "2023-01", y: 0.2973 },
      { x: "2023-07", y: 0.2882 },
    ],
  },
];
