import React from "react";
import { NavLink } from "react-router-dom";
import { Underline } from "./Underline";
import { Typography, useTheme } from "@mui/material";

export default function NavbarButtonLink({
  text,
  hyperlink,
  textWidth,
  buttonWidth,
  elementRef,
}) {
  const { palette } = useTheme();

  return (
    <NavLink
      id="navLinkUnderline"
      to={`/${hyperlink === "home" ? "" : hyperlink}`}
      style={{ textDecoration: "none" }}
    >
      {({ isActive }) => (
        <Underline
          id={isActive ? "active" : ""}
          palette={palette}
          width={`${textWidth * 1.5}px`}
          left={`${(buttonWidth - textWidth * 1.5) / 2 - 0.5}px`}
        >
          <Typography
            sx={{
              fontWeight: 400,
            }}
            ref={elementRef}
          >
            {text.replace(/_/g, " ")}
          </Typography>
        </Underline>
      )}
    </NavLink>
  );
}
