import { TextField } from "@mui/material";
import DropDownComponents from "components/DropDownComponents";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export default function EditHeader({
  label,
  contentName,
  content,
  setContent,
}) {
  const addContent = (type) => {
    const uniqueId = uuidv4();
    let newComponent = {
      uuid: uniqueId,
      orderNumber: content.contents.length + 1,
      contentType: type,
      content: "",
    };

    if (type === "video" || type === "image") {
      newComponent.imgVidDescription = "";
    }

    if (type === "image") {
      newComponent.images = [];
      newComponent.imgDisplaySize = "large";
    }

    if (type === "video") {
      newComponent.isAutoplay = false;
      newComponent.isControls = true;
      newComponent.isLoopingVideo = false;
      newComponent.isMuted = false;
    }

    if (type === "listBullet" || type === "listNumbered") {
      newComponent.list = [];
    }

    setContent({ ...content, contents: [...content.contents, newComponent] });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <div style={{ display: "flex", gap: "1rem" }}>
        <TextField
          label={label}
          name={contentName}
          variant="standard"
          fullWidth
          value={content[contentName]}
          onChange={(e) =>
            setContent({ ...content, [contentName]: e.target.value })
          }
          inputProps={{ style: { fontSize: "2rem" } }}
        />
      </div>
      <DropDownComponents addContent={addContent} />
    </div>
  );
}
