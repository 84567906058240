import { Button, CircularProgress } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";

export default function UploadCancelCMS({ setIsInput, loading = false }) {
  return (
    <div style={{ marginTop: "1rem", display: "flex", gap: "1rem" }}>
      <Button
        variant="outlined"
        onClick={() => {
          setIsInput(false);
        }}
        fullWidth
        endIcon={<CloseIcon />}
      >
        Abbruch
      </Button>
      <Button
        variant="contained"
        type="submit"
        fullWidth
        endIcon={
          loading ? (
            <CircularProgress size={"1.2rem"} color="inherit" />
          ) : (
            <UploadIcon />
          )
        }
      >
        Hochladen
      </Button>
    </div>
  );
}
