import { useState } from "react";
import NavbarButton from "./NavbarButton";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { Box, IconButton, SwipeableDrawer, useMediaQuery } from "@mui/material";
import { setLogout, setTheme } from "state";
import DarkModeSharpIcon from "@mui/icons-material/DarkModeSharp";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import MobileNavbarButton from "./MobileNavbarButton";
import NavbarDropDown from "./NavbarDropDown";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

export default function Navbar() {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const mode = useSelector((state) => state.mode);
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const navbarButtons = [
    "home",
    "projekte",
    "über_uns",
    "kontakt",
    // "kundenportal",
  ];
  const mobileNavbarButtons = [
    ...navbarButtons,
    "techn. berichte",
    "market data",
    "nachrichten",
  ];

  useGSAP(() => {
    gsap.fromTo(
      ".navbarButtonGsap",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: {
          amount: 0.5,
        },
      }
    );
  }, []);

  useGSAP(() => {
    if (!isDrawerOpen) return;
    gsap.fromTo(
      ".mobileNavGsap",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: {
          amount: 0.5,
        },
      }
    );
  }, [isDrawerOpen]);

  return (
    <>
      {isNotMobileScreen ? (
        <nav
          style={{
            backdropFilter: "blur(13px)",
            WebkitBackdropFilter: "blur(13px)",
            backgroundColor:
              mode === "dark"
                ? hexToRGBA(palette.neutral.darker, 0.7)
                : hexToRGBA("#ffffff", 0.7), //try to implement pallette
            borderBottom: `1px solid ${palette.neutral.main}`,
            // boxShadow: `0px 1px 3px ${palette.neutral.dark}`,
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: "2", //for some reason some mui components will overlap at 0, others at 1, therefore this is set to 2
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "2rem",
              alignItems: "center",
              margin: "1.5rem 1rem",
            }}
          >
            <Link
              to={"/"}
              style={{
                color: palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                minWidth: "12%",
              }}
            >
              <img
                // src="/assets/Logo_Windkraft-Regional.svg"
                src="/assets/logoWindhose.svg"
                alt="WKR Logo"
                style={{ width: "60px", cursor: "pointer" }}
              />
            </Link>
            {navbarButtons.map((link, i) => (
              <Box key={i} className="navbarButtonGsap">
                <NavbarButton text={link} />
              </Box>
            ))}
            <Box className="navbarButtonGsap">
              <NavbarDropDown />
            </Box>
            <div
              style={{
                alignSelf: "center",
                marginLeft: "auto",
              }}
            >
              <IconButton
                onClick={() => {
                  dispatch(setTheme());
                }}
                color="primary"
                name="Set Theme"
              >
                <DarkModeSharpIcon />
              </IconButton>
              {/* <div style={{ width: "40px", height: "40px" }}>
              
              </div> */}
              {/* Language option, above is the placeholder div
              <IconButton
                onClick={() => {
                  dispatch(setLanguage());
                }}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
              >
                <img
                  src={
                    language === "german"
                      ? "/assets/ger.svg"
                      : "/assets/eng.svg"
                  }
                  alt="Language"
                  style={{ width: "100%" }}
                />
              </IconButton> */}
            </div>
          </div>
        </nav>
      ) : (
        <nav
          style={{
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            backgroundColor:
              mode === "dark"
                ? hexToRGBA(palette.neutral.darker, 0.7)
                : hexToRGBA("#ffffff", 0.7), //try to implement pallette
            borderBottom: `1px solid ${palette.neutral.main}`,
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: "2",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={toggleDrawer(true)}
              sx={{
                position: "absolute",
                top: 3,
                left: 3,
                p: "2rem",
              }}
            >
              <MenuSharpIcon />
            </IconButton>
            <Link
              to={"/"}
              style={{
                margin: "0 20vw",
                padding: "0.5rem 1rem",
                color: palette.primary.main,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src="/assets/logoWindhose.svg"
                alt="WKR Logo"
                style={{ width: "70px", cursor: "pointer", margin: "1rem 0" }}
              />
            </Link>
          </div>
          <>
            <SwipeableDrawer
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <div
                style={{
                  backgroundColor: mode === "dark" && palette.neutral.darker,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Link
                  to={"/"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1rem 0",
                    margin: "1rem 0",
                  }}
                  onClick={() => {
                    setTimeout(() => {
                      setDrawerOpen(false);
                    }, 400);
                  }}
                >
                  <img
                    src="/favicon.svg"
                    alt="WKR Logo"
                    style={{ width: "30%" }}
                  />
                </Link>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {mobileNavbarButtons.map((link, i) => (
                    <Box key={i} className="mobileNavGsap">
                      <MobileNavbarButton
                        text={link}
                        setDrawerOpen={setDrawerOpen}
                        width="240px"
                      />
                    </Box>
                  ))}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "auto",
                    marginBottom: "2rem",
                    gap: "0.5rem",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      dispatch(setTheme());
                    }}
                    color="primary"
                    size="large"
                    name="Set Theme"
                  >
                    <DarkModeSharpIcon />
                  </IconButton>
                  {token && (
                    <IconButton
                      onClick={() => {
                        dispatch(setLogout());
                      }}
                      color="primary"
                      size="large"
                    >
                      <LogoutSharpIcon />
                    </IconButton>
                  )}
                  {/* Language option
                  <IconButton
                    onClick={() => {
                      dispatch(setLanguage());
                    }}
                    sx={{
                      width: "50px",
                      height: "50px",
                    }}
                  >
                    <img
                      src={
                        language === "german"
                          ? "/assets/ger.svg"
                          : "/assets/eng.svg"
                      }
                      alt="Language"
                      style={{ width: "100%" }}
                    />
                  </IconButton> */}
                </div>
              </div>
            </SwipeableDrawer>
          </>
        </nav>
      )}
    </>
  );
}
