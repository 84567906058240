import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React from "react";

export default function LoadingLogo({ scale = 1, mt = 0 }) {
  useGSAP(() => {
    gsap.to("#logoPart", {
      x: 30,
      yoyo: true,
      yoyoEase: true,
      repeat: -1,
      repeatDelay: 0.4,
      stagger: {
        amount: 0.2,
      },
    });
  }, []);

  return (
    <div
      style={{
        marginTop: mt,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        transform: `scale(${scale})`,
      }}
    >
      <div
        id="wkrLogo"
        style={{
          display: "flex", // flex
          height: "10rem", // h-20 (20 * 0.25rem = 5rem)
          width: "10rem", // w-40 (40 * 0.25rem = 10rem)
          alignItems: "center", // items-center
          gap: "0.5rem", // gap-2 (2 * 0.25rem = 0.5rem)
          transform: "rotate(-18deg)", // -rotate-18 (rotating by -18 degre
        }}
      >
        <img
          id="logoPart"
          src="./assets/loadingComponents/1.svg"
          style={{ height: "3.5rem" }} // h-14 (14 * 0.25rem = 3.5rem)
          alt="WKRLogo Component"
        />
        <img
          id="logoPart"
          src="./assets/loadingComponents/2.svg"
          style={{ height: "3.1rem" }} // h-12 (12 * 0.25rem = 3rem)
          alt="WKRLogo Component"
        />
        <img
          id="logoPart"
          src="./assets/loadingComponents/3.svg"
          style={{ height: "2.7rem" }} // h-10 (10 * 0.25rem = 2.5rem)
          alt="WKRLogo Component"
        />
      </div>
    </div>
  );
}
