import { useGSAP } from "@gsap/react";
import { Box, useTheme } from "@mui/material";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import NavbarButton from "./NavbarButton";
import { hexToRGBA } from "functions/values";

const translatePadding = "1rem";

export default function NavbarDropDown() {
  const { palette } = useTheme();

  const [isModal, setModal] = useState(false);

  // Animate out on click away
  const boxRef = useRef(null);
  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      animateOut();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // eslint-disable-line
  // Animate out on click away

  useGSAP(() => {
    if (isModal) return animateIn();
  }, [isModal]);

  function animateIn() {
    gsap.fromTo(
      "#navbarDialog",
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    );
    gsap.fromTo(
      ".button",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: {
          amount: 0.3,
        },
      }
    );
  }

  function animateOut() {
    gsap.to("#navbarDialog", {
      opacity: 0,
    });
    setTimeout(() => {
      setModal(false);
    }, 300);
  }

  const links = ["techn. berichte", "market data", "nachrichten"];

  return (
    <>
      <Box
        onMouseEnter={() => {
          setModal(true);
        }}
      >
        <NavbarButton
          type="dropDown"
          text="research"
          isModal={isModal}
          setModal={setModal}
        />
      </Box>
      {isModal && (
        <Box
          ref={boxRef}
          onMouseLeave={() => {
            animateOut();
          }}
          id="navbarDialog"
          sx={{
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "flex-start",
            position: "absolute",
            top: "4rem",
            backgroundColor: hexToRGBA(palette.background.default, 1),
            padding: translatePadding,
            transform: `translate(-${translatePadding}, 0)`,
            borderRadius: "0.5rem",
            border: `1px solid ${palette.neutral.dark}`,
            backdropFilter: "blur(13px)", // does not work because of nesting
            WebkitBackdropFilter: "blur(13px)",
          }}
        >
          {links.map((link, i) => (
            <Box
              key={i}
              className="button"
              onClick={() => {
                setTimeout(() => {
                  animateOut();
                }, 300);
              }}
            >
              <NavbarButton text={link} />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}
