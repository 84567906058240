import { Box, Button, IconButton, Typography } from "@mui/material";
import { bytesToMB, VisuallyHiddenInput } from "functions/values";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";

export default function ReportPdfInput({
  file,
  handleChange,
  language,
  reportId = undefined,
}) {
  const [fileSize, setFileSize] = useState(0);
  const fileInputRef = useRef(null);
  const { palette } = useTheme();

  useEffect(() => {
    if (file) {
      setFileSize(bytesToMB(file.size));
    }
  }, [file]); // eslint-disable-line

  return (
    <Box sx={{ width: "100%" }}>
      <label htmlFor="pdfInput">
        <Typography
          sx={{
            fontSize: "1rem",
            cursor: "pointer",
            textTransform: "uppercase",
            letterSpacing: 2,
            textAlign: "center",
          }}
          m="1rem 0 2rem"
          color={palette.neutral.medium}
        >
          {language === "ger"
            ? "Deutsche Version als ein PDF"
            : "English Version as a PDF"}
        </Typography>
      </label>
      <Button
        component="label"
        variant="contained"
        endIcon={
          <img
            src={`./assets/${language}.svg`}
            alt={language}
            style={{ width: 25 }}
          />
        }
        fullWidth
      >
        PDF hochladen
        <VisuallyHiddenInput
          name={`reportFile${
            language.charAt(0).toUpperCase() + language.slice(1)
          }`}
          id="pdfInput"
          ref={fileInputRef}
          type="file"
          accept="application/pdf"
          multiple={false}
          onInput={(e) => {
            const selectedFile = e.target.files[0];
            if (!selectedFile) return;
            handleChange(e, reportId);
          }}
          // required
        />
      </Button>
      {file && (
        <Box
          sx={{
            mt: "1rem",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <IconButton
            onClick={() => {
              fileInputRef.current.value = null;
              const element = {
                target: {
                  name: `reportFile${
                    language.charAt(0).toUpperCase() + language.slice(1)
                  }`,
                  value: null,
                },
              };
              handleChange(element, reportId);
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box>
            <Typography
              sx={{
                overflowWrap: "break-word",
                wordWrap: "break-word",
                msWordBreak: "break-all",
                wordBreak: "break-word", // or "break-all", depending on your preference
                msHyphens: "auto",
                MozHyphens: "auto",
                WebkitHyphens: "auto",
                hyphens: "auto",
              }}
            >
              {file.name || file}
            </Typography>
            {fileSize !== "NaN" && (
              <Typography
                variant="h4"
                sx={{ color: fileSize >= 7 ? "red" : "green" }}
              >
                <span>{fileSize} MB</span>
                {fileSize >= 7 && (
                  <Typography component="span" sx={{ ml: 1, color: "red" }}>
                    Bad Size
                  </Typography>
                )}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
