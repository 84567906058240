import { Box, Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ListBullet({ content, listPoints, setListPoints }) {
  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");

  useEffect(() => {
    addList();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!content || content.list.length === 0) return;
    setListPoints(content.list);
  }, [content]); // eslint-disable-line

  const addList = () => {
    const newPoint = {
      pointOrder: listPoints.length,
      textContent: "",
    };
    setListPoints([...listPoints, newPoint]);
  };

  const updateList = (pointOrder, value) => {
    const updatedContent = listPoints.map((point) => {
      if (point.pointOrder === pointOrder) {
        // Return a new object with the updated textContent
        return {
          ...point,
          textContent: value,
        };
      }
      return point;
    });
    setListPoints(updatedContent);
  };

  const deletePoint = () => {
    const updatedListPoints = [...listPoints];
    const indexToDelete = updatedListPoints.findIndex(
      (point) => point.pointOrder === listPoints.length - 1
    );
    if (indexToDelete !== -1) {
      updatedListPoints.splice(indexToDelete, 1);
    }
    setListPoints(updatedListPoints);
  };

  return (
    <>
      {listPoints.map((point) => (
        <Box
          sx={{ mt: 1, mb: "1rem", display: "flex", alignItems: "center" }}
          key={point.pointOrder}
        >
          <CircleIcon sx={{ mr: 1, my: 0.5, fontSize: "0.5rem" }} />
          <TextField
            variant="standard"
            fullWidth
            value={point.textContent}
            onChange={(e) => updateList(point.pointOrder, e.target.value)}
            multiline //causes bug
          />
        </Box>
      ))}
      {listPoints && listPoints.length > 0 && (
        <Button
          color="error"
          variant="outlined"
          startIcon={<DeleteIcon />}
          fullWidth
          onClick={deletePoint}
        >
          {isGerman
            ? "Letzten Aufzählungspunkt Löschen"
            : "Delete Last Bullet Point"}
        </Button>
      )}
      <Button
        startIcon={<AddIcon />}
        fullWidth
        sx={{ mt: "1rem" }}
        onClick={addList}
      >
        {isGerman ? "Neuer Aufzählungspunkt" : "New Bullet Point"}
      </Button>
    </>
  );
}
