import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import React from "react";

export default function ReportLanguage({ reportLanguage, setLanguage }) {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  return (
    <Box>
      {isNotMobileScreen ? (
        <ToggleButtonGroup
          value={reportLanguage}
          exclusive
          onChange={(e) => {
            setLanguage(e.target.value || e.target.attributes.value.nodeValue);
          }}
        >
          <ToggleButton value="ger">
            <img
              value="ger"
              src="./assets/ger.svg"
              alt="reportLanguage"
              style={{ width: "30px" }}
            />
          </ToggleButton>
          <ToggleButton value="eng">
            <img
              value="eng"
              src="./assets/eng.svg"
              alt="reportLanguage"
              style={{ width: "30px" }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      ) : (
        <FormControl fullWidth>
          <InputLabel id="select-label">Sprache</InputLabel>
          <Select
            labelId="select-label"
            value={reportLanguage}
            label="Sprache"
            onChange={(e) => setLanguage(e.target.value)}
          >
            <MenuItem value="ger">
              <img
                src="./assets/ger.svg"
                alt="reportLanguage"
                style={{ width: "30px" }}
              />
            </MenuItem>
            <MenuItem value="eng">
              <img
                src="./assets/eng.svg"
                alt="reportLanguage"
                style={{ width: "30px" }}
              />
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
