import { Box, TextField } from "@mui/material";

export default function ReportTextInputs({ report, handleChange }) {
  const {
    _id = null,
    reportTitleGer,
    reportTitleEng,
    reportDescriptionGer,
    reportDescriptionEng,
  } = report;

  return (
    <>
      <Box className="inputs" display="flex" gap="1rem" m="1rem 0 0">
        <TextField
          name="reportTitleGer"
          label="Berichttitel"
          variant="outlined"
          fullWidth
          multiline
          value={reportTitleGer}
          onChange={(e) => handleChange(e, _id)}
          minLength={3}
          maxLength={100}
          required
        />
        <TextField
          name="reportTitleEng"
          label="Report Title"
          variant="outlined"
          fullWidth
          multiline
          value={reportTitleEng}
          onChange={(e) => handleChange(e, _id)}
          minLength={3}
          maxLength={100}
          required
        />
      </Box>

      <Box className="inputs" display="flex" gap="1rem" m="0 0 1rem">
        <TextField
          sx={{ mt: "1rem" }}
          variant="outlined"
          name="reportDescriptionGer"
          label="Kurze Beschreibung"
          fullWidth
          multiline
          value={reportDescriptionGer}
          onChange={(e) => handleChange(e, _id)}
          maxLength={300}
        />
        <TextField
          sx={{ mt: "1rem" }}
          variant="outlined"
          name="reportDescriptionEng"
          label="Short Description"
          fullWidth
          multiline
          value={reportDescriptionEng}
          onChange={(e) => handleChange(e, _id)}
          maxLength={300}
        />
      </Box>
    </>
  );
}
