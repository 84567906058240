import { Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

export default function ReportEditingSnackbar({
  edting,
  setEditing,
  animateCheckboxes,
  setEditReport,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!edting) return;
    setOpen(true);
  }, [edting]);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEditReport([]);

    setOpen(false);
    animateCheckboxes();

    setTimeout(() => {
      setEditing(false);
    }, 1000);
  };

  const action = (
    <>
      <Button
        size="small"
        variant="outlined"
        color="inherit"
        onClick={handleClose}
      >
        Verlassen
      </Button>
    </>
  );
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      message={"Bearbeitungs Modus"}
      action={action}
    />
  );
}
