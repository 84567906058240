import { Divider, IconButton, useMediaQuery } from "@mui/material";
import TextContent from "components/TextContent";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ContentModule from "components/ContentModule";
import { v4 as uuidv4 } from "uuid";
import PageTitle from "components/PageTitle";
import ContactForm from "components/modules/ContactForm";
import { deepEqual, serverUrl } from "functions/values";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useFetch from "hooks/useFetch";
import LineGraph from "components/modules/LineGraph";
import EditButton from "components/cms/EditButton";
import EditHeader from "components/cms/EditHeader";
import PatchButtons from "components/cms/PatchButtons";
import LoadingLogo from "components/loading/LoadingLogo";
import ErrorComponent from "components/ErrorComponent";

export default function Page({ pageTitle }) {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  const token = useSelector((state) => state.token);
  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");

  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [page, setPage] = useState({});
  const [originalPage, setOriginalPage] = useState({});
  const [editing, setEditing] = useState(false);
  const [updateImages, setUpdateImages] = useState(false);

  const pageName = location.pathname.replace(/^\/|\/$/g, "") || "home";
  const { isPending: loading, fetchData } = useFetch();

  useGSAP(() => {
    if (loading) return;

    const contents = gsap.utils.toArray(".content");
    const lists = gsap.utils.toArray(".listAnimation");

    animateItems(contents);
    animateItems(lists);

    function animateItems(items) {
      items.forEach((content) => {
        gsap.fromTo(
          content,
          {
            y: 40,
            opacity: 0,
          },
          {
            scrollTrigger: {
              trigger: content,
              start: "top 90%",
              toggleActions: "play none none reverse",
            },
            y: 0,
            opacity: 1,
            stagger: {
              amount: 0.2,
              from: "start", // Stagger from the start of the animation
            },
          }
        );
      });
    }
  }, [loading]);

  useEffect(() => {
    setEditing(false);
    document.title = `WKR | ${pageTitle}`;

    fetchData(
      `${serverUrl}/pages/${pageTitle === "404" ? pageTitle : pageName}`,
      undefined,
      (result) => {
        setPage(result);
        setOriginalPage(result);
      },
      (error) => {
        setMessage(error.message);
      }
    );
  }, [pageTitle]); // eslint-disable-line

  // Content management
  const changeContents = (updatedContents) => {
    setPage({ ...page, contents: updatedContents });
  };

  const updateOrder = (oldNumber, newNumber) => {
    const updatedContents = page.contents.map((content) => {
      if (content.orderNumber === oldNumber) {
        return { ...content, orderNumber: newNumber };
      } else if (content.orderNumber === newNumber) {
        return { ...content, orderNumber: oldNumber };
      }
      return content;
    });

    changeContents(updatedContents);
  };

  const removeContent = (orderNumberToRemove) => {
    const updatedContents = page.contents
      .filter((content) => content.orderNumber !== orderNumberToRemove)
      .map((content) => {
        if (content.orderNumber > orderNumberToRemove) {
          return { ...content, orderNumber: content.orderNumber - 1 };
        }
        return content;
      });

    changeContents(updatedContents);
  };
  // Content Management

  // Patch Content
  async function patchProject() {
    setLoading(true);
    if (deepEqual(page, originalPage)) return setEditing(false);

    let contentsForServer = page;
    const formData = new FormData();

    formData.append("_id", contentsForServer._id);
    formData.append("title", contentsForServer.title);

    // Comparing images
    function dataURLtoFile(dataURL, filename) {
      const arr = dataURL.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    function getRandomUUID() {
      return uuidv4();
    }

    let imagesToDelete = [];
    let videosToDelete = [];

    imagesToDelete = findImagesToDelete(
      contentsForServer.contents,
      originalPage.contents
    );

    contentsForServer.contents.forEach((content) => {
      if (content.images) {
        content.images.forEach((image, imageIndex) => {
          if (image.cropped) {
            const croppedImage = dataURLtoFile(
              image.dataURL,
              `${
                content.orderNumber
              }-contentOrder-${imageIndex}-index-${getRandomUUID()}-${Date.now()}.${
                image.cropped
              }`
            );
            formData.append("images", croppedImage);
            content.images[imageIndex] = croppedImage.name;
          }
          if (image.edited && image.cropped === undefined) {
            const file = image.file;
            const extension = file.name.split(".").pop();
            const newName = `${
              content.orderNumber
            }-contentOrder-${imageIndex}-index-${getRandomUUID()}-${Date.now()}.${extension}`;

            const newFile = new File([file], newName, { type: file.type });
            formData.append("images", newFile);
            content.images[imageIndex] = newName;
          }
          if (
            image.edited === undefined &&
            image.cropped === undefined &&
            image.file
          ) {
            content.images[imageIndex] = image.file.name;
          }
        });
      }
      if (content.video) {
        if (content.video.edited) {
          const file = content.video.file;
          const extension = file.name.split(".").pop();
          const newName = `${
            content.orderNumber
          }-contentOrder-${getRandomUUID()}-${Date.now()}.${extension}`;

          const newFile = new File([file], newName, { type: file.type });
          formData.append("videos", newFile);
          content.video = newName;
          const originalVideo = originalPage.contents.find(
            (cont) => cont.uuid === content.uuid
          );
          if (originalVideo) videosToDelete.push(originalVideo.video);
        }
      }
    });

    // If entire module got deleted
    originalPage.contents.forEach((content) => {
      if (
        !contentsForServer.contents.some(
          (editedContent) => editedContent.uuid === content.uuid
        )
      ) {
        if (content.images) {
          content.images.forEach((image) => {
            imagesToDelete.push(image);
          });
        }
        if (content.video) {
          videosToDelete.push(content.video);
        }
      }
    });

    function findImagesToDelete(editedContents, originalContents) {
      editedContents.forEach((editedContent) => {
        const originalContent = originalContents.find((originalContent) => {
          return originalContent.uuid === editedContent.uuid;
        });

        if (!originalContent) {
          return;
        }

        let originalImageObjects = [];
        if (originalContent.images) {
          originalImageObjects = Array.from(originalContent.images).map(
            (imageName) => ({
              name: imageName,
              hasImage: false,
            })
          );
        }

        editedContent.images &&
          editedContent.images.forEach((editedImage) => {
            originalImageObjects.forEach((obj) => {
              if (editedImage.file && obj.name === editedImage.file.name) {
                obj.hasImage = true;
              }
              if (
                editedImage.file &&
                obj.name === editedImage.file.name &&
                editedImage.cropped
              ) {
                obj.hasImage = false;
              }
              if (!editedImage.file && !editedContent.edited) {
                obj.hasImage = true;
              }
            });
          });

        originalImageObjects.forEach((obj) => {
          if (obj.hasImage === false) {
            imagesToDelete.push(obj.name);
          }
        });
      });
      return imagesToDelete;
    }

    const imagesToDeleteSerialised = JSON.stringify(imagesToDelete);
    formData.append("imagesToDelete", imagesToDeleteSerialised);

    const videosToDeleteSerialised = JSON.stringify(videosToDelete);
    formData.append("videosToDelete", videosToDeleteSerialised);

    contentsForServer.contents.forEach((content) => {
      if (content.images && content.images.length === 0) {
        delete content.images;
      }
    });

    const contentsSerialised = JSON.stringify(contentsForServer.contents);
    formData.append("contents", contentsSerialised);

    try {
      const response = await fetch(`${serverUrl}/pages/patch`, {
        method: "PATCH",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });
      if (response.ok) {
        const updatedPage = {
          ...contentsForServer,
          updatedAt: Date.now(),
        };
        setPage(updatedPage);
        setOriginalPage(updatedPage);
        setEditing(false);
        setUpdateImages(!updateImages);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  if (loading) {
    return <LoadingLogo scale={isNotMobileScreen ? 0.65 : 0.8} mt={"8rem"} />;
  }

  return (
    <TextContent
      isNotMobileScreen={isNotMobileScreen}
      style={
        !editing &&
        page.contents &&
        page.contents.some(
          (content) =>
            page.title === "" &&
            content.orderNumber === 1 &&
            content.contentType === "video"
        )
          ? { marginTop: isNotMobileScreen ? "40vh" : "36vh" }
          : {}
      }
    >
      <div style={{ maxWidth: "800px", width: "100%", marginBottom: "5rem" }}>
        {message && <ErrorComponent errorMessage={message} />}
        {!editing && page.title && (
          <div
            style={{
              display: "flex",
              alignItems: [isNotMobileScreen ? "center" : "end"],
              gap: "1rem",
              marginBottom: "1rem",
            }}
          >
            {!message && (
              <div>
                <PageTitle title={page.title} />
              </div>
            )}
            {!message && token && <EditButton setEditing={setEditing} />}
          </div>
        )}
        {!editing && page.title && <Divider sx={{ m: "1rem 0" }} />}
        {page.title === "" && token && (
          <div style={{ position: "fixed", top: "50%", right: "1rem" }}>
            <IconButton
              color="primary"
              style={{ position: "relative" }}
              onClick={() => setEditing(true)}
            >
              <EditIcon />
            </IconButton>
          </div>
        )}
        {editing &&
          (isNotMobileScreen ? (
            <h3 style={{ margin: "0" }}>
              {isGerman ? "Bearbeiten:" : "Edit"} {page.title}
            </h3>
          ) : (
            <h4 style={{ margin: "0" }}>
              {isGerman ? "Bearbeiten:" : "Edit"} {page.title}
            </h4>
          ))}
        {editing && (
          <EditHeader
            label="Titel"
            contentName="title"
            content={page}
            setContent={setPage}
          />
        )}
        {page.contents &&
          page.contents
            .slice()
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((content) => (
              <ContentModule
                key={content.uuid}
                content={content}
                contents={page.contents}
                updateImages={updateImages}
                changeContents={changeContents}
                updateOrder={updateOrder}
                removeContent={removeContent}
                isEditing={editing}
                isJustDisplaying={!editing}
                isEdited={true}
                pageTitle={page.title}
              />
            ))}
        {pageTitle === "Development" && <LineGraph />}
        {!editing && page.page === "contact" && <ContactForm />}
        {editing && (
          <PatchButtons
            cancelFunction={() => {
              setEditing(false);
              setPage(originalPage);
            }}
            patchRequest={patchProject}
            isLoading={isLoading}
          />
        )}
      </div>
    </TextContent>
  );
}
