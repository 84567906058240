import React, { useEffect } from "react";
import TextContent from "components/TextContent";
import { Box, useMediaQuery } from "@mui/material";
import PageTitle from "components/PageTitle";
import Divider from "@mui/material/Divider";

const MarketData = ({ pageTitle }) => {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  useEffect(() => {
    document.title = `WKR | ${pageTitle}`;
  }, [pageTitle]);

  return (
    <TextContent isNotMobileScreen={isNotMobileScreen}>
      <div style={{ maxWidth: "800px", width: "100%", marginBottom: "5rem" }}>
        <div>
          <PageTitle title={pageTitle} />
        </div>
        <Divider sx={{ m: "1rem 0" }} />
        <Box
          sx={{
            overflow: "scroll",
          }}
        >
          <iframe
            style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
            width="800"
            height="450"
            src="https://embed.figma.com/proto/ryBLGR7IH8YWZng2vF1OjI/Energy-Presentation-(GERMAN)?node-id=1-2&node-type=frame&scaling=contain&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2&embed-host=share"
            allowFullScreen
            title="Market Data"
          />
        </Box>
      </div>
    </TextContent>
  );
};

export default MarketData;
