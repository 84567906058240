import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNotification } from "context/NotificationContext";
import { setEditReportLogin, setLogin, setReportLogin } from "state";

export default function LoginComponent({
  loginUrl,
  redirectUrl,
  loginType = "project", // "admin" or "project", controls which login form to use
  usernameLabel = "Nutzername",
  passwordLabel = "Passwort",
}) {
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    usernameReports: "",
    passwordReports: "",
  });
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNotification = useNotification();

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Determine payload based on login type
      const payload =
        loginType === "admin"
          ? { username: formState.username, password: formState.password }
          : {
              usernameReports: formState.usernameReports,
              passwordReports: formState.passwordReports,
            };

      const response = await fetch(loginUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        // data.msg = "You are not permitted to login"
        return showNotification(
          "error",
          "Nutzername u. o. Passwort falsch, Groß- und Kleinschreibung beachten!",
          8000
        );
      }

      // Handle project login roles (edit/viewer)
      if (loginType === "project") {
        if (data.edit) {
          dispatch(setEditReportLogin({ token: data.token }));
        } else {
          dispatch(setReportLogin({ token: data.token }));
        }
      }

      if (loginType === "admin") {
        dispatch(setLogin({ token: data.token }));
      }

      // Redirect after successful login
      navigate(redirectUrl);
    } catch (error) {
      showNotification("error", "Server- oder Netzwerkfehler");
      console.error("Login error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "800px", width: "100%", margin: "10vh auto" }}>
      <form
        onSubmit={handleSubmit}
        style={{
          marginTop: "10vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {loginType === "admin" ? (
          <>
            <TextField
              inputProps={{ style: { fontSize: "1rem" } }}
              InputLabelProps={{ style: { fontSize: "1rem" } }}
              name="username"
              label={usernameLabel} // Admin login label
              variant="standard"
              fullWidth
              value={formState.username}
              onChange={handleChange}
              required
            />

            <TextField
              inputProps={{ style: { fontSize: "1rem" } }}
              InputLabelProps={{ style: { fontSize: "1rem" } }}
              name="password"
              label={passwordLabel} // Admin password label
              variant="standard"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={formState.password}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                      style={{ margin: "0 5px" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : (
          <>
            <TextField
              inputProps={{ style: { fontSize: "1rem" } }}
              InputLabelProps={{ style: { fontSize: "1rem" } }}
              name="usernameReports"
              label={usernameLabel} // Project login label
              variant="standard"
              fullWidth
              value={formState.usernameReports}
              onChange={handleChange}
              required
            />

            <TextField
              inputProps={{ style: { fontSize: "1rem" } }}
              InputLabelProps={{ style: { fontSize: "1rem" } }}
              name="passwordReports"
              label={passwordLabel} // Project password label
              variant="standard"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={formState.passwordReports}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                      style={{ margin: "0 5px" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}

        <Button
          variant="outlined"
          type="submit"
          sx={{ fontSize: "1rem" }}
          endIcon={
            loading && <CircularProgress size={"1.2rem"} color="inherit" />
          }
        >
          Login
        </Button>
      </form>
    </div>
  );
}
