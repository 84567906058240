import { ResponsiveLine } from "@nivo/line";
import useNivoTheme from "../../hooks/useNivoTheme";
import { useEffect, useState } from "react";
import { data, dataAdjustedForInflation } from "functions/costOfElec";
import { Button } from "@mui/material";
import { lineDefaults } from "nivo/lineDefaults";
import { useTheme } from "@emotion/react";

const LineGraph = () => {
  const { palette } = useTheme();
  const { nivoTheme } = useNivoTheme();

  const [withInflation, setInflation] = useState(false);
  const [dataForGraph, setDataForGraph] = useState();

  useEffect(() => {
    setDataForGraph(withInflation ? dataAdjustedForInflation : data);
  }, [withInflation]);

  return (
    <div style={{ height: 400 }}>
      <ResponsiveLine
        // onMouseEnter={() => setPointSize(7)}
        enablePoints={true}
        pointColor={palette.background.default}
        margin={{
          top: 50,
          right: 80,
          bottom: 70,
          left: 50,
        }}
        {...lineDefaults}
        colors={{ scheme: "set1" }} // red-grey
        theme={nivoTheme}
        data={dataForGraph}
        // gridXValues={gridXValues}
        // gridYValues={gridYValues}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0, // -50 if x data points > say 20
          legend: dataForGraph && dataForGraph[0].xName,
          legendOffset: 60,
          legendPosition: "middle",
          // tickValues: gridXValues,
          tickValues: "every 2 years",
          format: "%Y-%m",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: dataForGraph && dataForGraph[0].yName,
          legendOffset: -40,
          legendPosition: "middle",
        }}
        yScale={{
          type: "linear",
          min: "0", // fill will bleed over if this is not set to 0
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        xFormat="time:%Y-%m"
        xScale={{
          format: "%Y-%m",
          precision: "month",
          type: "time",
          useUTC: false,
        }}
        /*
        tooltip={({ id, value, color }) => (
          <div
            style={{
              padding: 12,
              color,
              background: "#222222",
            }}
          >
            <span>Look, I'm custom :)</span>
            <br />
            <strong>
              {id}: {value}
            </strong>
          </div>
        )}
        */
      />
      <Button onClick={() => setInflation(!withInflation)} fullWidth>
        {withInflation ? "Without inflation" : "With inflation"}
      </Button>
    </div>
  );
};

export default LineGraph;
