import { useNotification } from "context/NotificationContext";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function usePatch(url) {
  const showMessage = useNotification();

  const [isPending, setIsPending] = useState(false);

  const token = useSelector((state) => state.token);
  const editReportToken = useSelector((state) => state.editReportToken);

  async function patchData(
    body,
    successFunction = () => {},
    errorFunction = () => {}
  ) {
    setIsPending(true);

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token || editReportToken}`,
        },
        body,
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const result = await response.json();
      successFunction(result);
    } catch (err) {
      console.error("Fetch error:", err);
      showMessage("error", err.message, 5000, "left");
      errorFunction();
    } finally {
      setIsPending(false);
    }
  }
  return { isPending, patchData };
}
