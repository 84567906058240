import { Button, Box, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export default function ConfirmCancel({ abortFunction, loading }) {
  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <Button
        variant="outlined"
        onClick={() => {
          abortFunction(false);
        }}
        fullWidth
        endIcon={<CloseIcon />}
      >
        Abbruch
      </Button>
      <Button
        variant="contained"
        type="submit"
        fullWidth
        endIcon={
          loading ? (
            <CircularProgress size={"1.2rem"} color="inherit" />
          ) : (
            <CheckIcon />
          )
        }
        disabled={loading}
      >
        Bestätigen
      </Button>
    </Box>
  );
}
