import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PageTitle from "components/PageTitle";
import TextContent from "components/TextContent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReportLogout } from "state";
import LogoutIcon from "@mui/icons-material/Logout";
import ReportPost from "components/report/ReportPost";
import ReportsContainer from "components/report/ReportsContainer";
import ReportLanguage from "components/report/ReportLanguage";
import ReportSpeedDial from "components/report/ReportSpeedDial";
import { useReports } from "context/ReportContext";
import LoginComponent from "../components/LoginComponent";
import { serverUrl } from "functions/values";
import ErrorComponent from "components/ErrorComponent";

export default function Reports({ pageTitle }) {
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const reportToken = useSelector((state) => state.reportToken);
  const editReportToken = useSelector((state) => state.editReportToken);

  const { fetchReports, error } = useReports();

  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [newReportInput, setNewReportInput] = useState(false);

  // Language
  const [reportLanguage, setLanguage] = useState("ger");

  useEffect(() => {
    document.title = `WKR | ${pageTitle}`;
  }, [pageTitle]); // eslint-disable-line

  useEffect(() => {
    if (!token && !reportToken && !editReportToken) return;
    fetchReports();
  }, [token, reportToken, editReportToken]); // eslint-disable-line

  return (
    <TextContent isNotMobileScreen={isNotMobileScreen}>
      <div style={{ maxWidth: "800px", width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: [isNotMobileScreen ? "center" : "end"],
            justifyContent: "space-between",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <PageTitle title={pageTitle} />

          {(reportToken || editReportToken) && (
            <>
              <Box style={{ display: "flex", gap: "0.5rem" }}>
                <Tooltip title={<Typography>Ausloggen</Typography>}>
                  <IconButton
                    color="primary"
                    onClick={() => dispatch(setReportLogout())}
                  >
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
                <ReportLanguage
                  reportLanguage={reportLanguage}
                  setLanguage={setLanguage}
                />
              </Box>
              {editReportToken && (
                <ReportSpeedDial
                  setNewReportInput={setNewReportInput}
                  setEditing={setEditing}
                  setDeleting={setDeleting}
                />
              )}
            </>
          )}
        </div>
        <Divider sx={{ m: "1rem 0" }} />

        {error && <ErrorComponent errorMessage={error} />}

        {!reportToken && !editReportToken && (
          <LoginComponent
            loginUrl={`${serverUrl}/auth/reports/login`}
            redirectUrl="/reports"
            loginType="project" // Specify project login
          />
        )}

        {editReportToken && newReportInput && (
          <ReportPost setNewReportInput={setNewReportInput} />
        )}

        {(reportToken || editReportToken) && (
          <ReportsContainer
            reportLanguage={reportLanguage}
            editing={editing}
            setEditing={setEditing}
            deleting={deleting}
            setDeleting={setDeleting}
          />
        )}

        {/* Notification tests
        <Button
          onClick={() =>
            showNotification("success", "The operation was successful!")
          }
        >
          Positive
        </Button>
        <Button
          onClick={() =>
            showNotification("error", "The operation was unsuccessful!")
          }
        >
          Netive
        </Button>
        <Button
          onClick={() =>
            showNotification("info", "The operation was successful!")
          }
        >
          Info
        </Button>
        <Button
          onClick={() =>
            showNotification("warning", "The operation was unsuccessful!")
          }
        >
          Warning
        </Button>
         */}
      </div>
    </TextContent>
  );
}
