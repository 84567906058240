import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function ImageInputRadio({ imgDisplaySize, updateContent }) {
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Bildgroesse</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={imgDisplaySize}
        onChange={(e) => {
          updateContent("imgDisplaySize", e.target.value);
        }}
      >
        <FormControlLabel value="small" control={<Radio />} label="Klein" />
        <FormControlLabel value="medium" control={<Radio />} label="Medium" />
        <FormControlLabel value="large" control={<Radio />} label="Gross" />
      </RadioGroup>
    </FormControl>
  );
}
