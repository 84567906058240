import React, { useState, useEffect, useRef } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSwipeable } from "react-swipeable";

function Carousel({ images, imgDisplaySize, loading }) {
  const { palette } = useTheme();
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  const [dragging, setDragging] = useState(false);
  const [translateX, setTranslateX] = useState(0);
  const startX = useRef(0);
  const threshold = 100; // Define the threshold for swipe detection
  const [index, setIndex] = useState(0);

  const handlePrevious = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  // Disable when Cropping
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNext();
      setDragging(false);
      setTranslateX(0);
    },
    onSwipedRight: () => {
      handlePrevious();
      setDragging(false);
      setTranslateX(0);
    },
    onSwiping: (eventData) => {
      setTranslateX(eventData.deltaX);
    },
    onSwipeStart: (eventData) => {
      startX.current = eventData.initial[0];
      setDragging(true);
    },
    onSwipeEnd: () => {
      setDragging(false);
      setTranslateX(0);
    },
  });

  const handleMouseDown = (event) => {
    startX.current = event.clientX;
    setDragging(true);
    event.preventDefault(); // Prevent default drag-and-drop behavior
  };

  useEffect(() => {
    if (images.length === 1) return;
    let deltaX;
    const handleMouseMove = (event) => {
      if (dragging) {
        deltaX = event.clientX - startX.current;
        setTranslateX(deltaX);
      }
    };

    const handleMouseUp = () => {
      if (dragging) {
        if (Math.abs(deltaX) > threshold) {
          if (deltaX > 0) {
            handlePrevious();
          } else {
            handleNext();
          }
        }
        setDragging(false);
        setTranslateX(0);
      }
    };

    if (dragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!images) return null;

  return (
    <Box
      {...(images.length > 1 ? handlers : {})}
      sx={{
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          borderRadius: "0.4rem",
          width:
            (imgDisplaySize === "large" && "100%") ||
            (imgDisplaySize === "medium" && "70%") ||
            (imgDisplaySize === "small" && isNotMobileScreen
              ? "200px"
              : "150px"),
        }}
      >
        <Box
          onMouseDown={images.length > 1 ? handleMouseDown : () => {}}
          sx={{
            display: "flex",
            transition: dragging ? "none" : "transform 0.3s ease",
            transform: `translateX(calc(-${index * 100}% + ${translateX}px))`,
          }}
        >
          {images.map((image, idx) => (
            <img
              key={idx}
              style={{
                display: "block",
                width: "100%",
                minWidth: "100%",
              }}
              src={image.dataURL}
              alt={"user generated image" + idx}
            />
          ))}
        </Box>
      </Box>
      {images && images.length > 1 && (
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            // backgroundColor: "rgba(56, 56, 56, 0.5)",
            // borderRadius: "1rem",
            position: "absolute",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "0.5rem",
          }}
        >
          {images.map((_, i) => {
            return (
              <Box
                key={i}
                sx={{
                  backgroundColor:
                    index === i ? palette.primary.main : "#FFFFFF",
                  height: "10px",
                  width: "10px",
                  borderRadius: "100%",
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: index !== i && "#E0E0E0", // Change color on hover
                  },
                }}
                onClick={() => setIndex(i)}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default Carousel;
