import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  token: "",
  reportToken: "",
  editReportToken: "",
  language: "german",
  isCookiesAccepted: false,
  reports: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTheme: (state) => {
      state.mode = state.mode === "dark" ? "light" : "dark";
    },
    setLanguage: (state) => {
      state.language = state.language === "german" ? "english" : "german";
    },

    // Tokens
    setLogin: (state, action) => {
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.token = "";
    },

    setReportLogin: (state, action) => {
      state.reportToken = action.payload.token;
    },
    setEditReportLogin: (state, action) => {
      state.editReportToken = action.payload.token;
    },
    setReportLogout: (state) => {
      state.editReportToken = "";
      state.reportToken = "";
    },
    // Tokens

    // Projects
    setProjectState: (state, action) => {
      state.projects = action.payload;
    },
    addProjectState: (state, action) => {
      let project = {};
      project.projectName = action.payload.formState.projectName;
      state.projects.push(project);
    },
    unlinkProject: (state, action) => {
      state.projects = state.projects.filter(
        (project) => project._id !== action.payload.projectId
      );
    },
    // Projects

    // Reports
    setReportState: (state, action) => {
      state.reports = action.payload;
    },
    addReportState: (state, action) => {
      state.reports.unshift(action.payload);
    },
    unlinkReports: (state, action) => {
      const reportIds = action.payload;
      state.reports = state.reports.filter(
        (report) => !reportIds.includes(report._id)
      );
    },
    // Reports

    // Cookies
    setCookiePreferences: (state, action) => {
      state.isCookiesAccepted = action.payload.isCookiesAccepted;
    },
  },
});

export const {
  setTheme,
  setLogin,
  setLogout,
  setReportLogin,
  setEditReportLogin,
  setReportLogout,
  setProjectState,
  addProjectState,
  unlinkProject,
  setReportState,
  addReportState,
  unlinkReports,
  setCookiePreferences,
} = authSlice.actions;
export default authSlice.reducer;
