import { IconButton } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";

export default function EditButton({ setEditing }) {
  return (
    <IconButton
      color="primary"
      style={{ position: "relative" }}
      onClick={() => setEditing(true)}
    >
      <EditIcon />
    </IconButton>
  );
}
