import { amazonUrl } from "functions/values";
import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default function VideoComponent({ setVideo, content, setLoading }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (content) {
      initVideoPlayer();
    }
  }, [content]); //eslint-disable-line

  const initVideoPlayer = () => {
    fetch(`${amazonUrl}/${content.video}`)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const videoPlayer = videojs(videoRef.current, {
          autoplay: content.isAutoplay,
          controls: content.isControls,
          muted: content.isMuted,
          loop: content.isLoopingVideo,
          sources: [
            {
              src: blobUrl,
              type: "video/mp4",
            },
            {
              src: blobUrl,
              type: "video/quicktime",
            },
          ],
        });

        videoPlayer.ready(() => {
          setVideo({ dataURL: blobUrl }); // Set video to the blob URL
          setLoading(false);
        });

        return () => {
          if (videoPlayer) {
            videoPlayer.dispose();
          }
          URL.revokeObjectURL(blobUrl);
        };
      })
      .catch((error) => console.error(error));
  };

  return (
    <div style={{ width: "100%", maxWidth: "100%" }}>
      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          paddingBottom: "56.25%", // Aspect ratio 16:9 (9/16 * 100 = 56.25)
          position: "relative",
        }}
      >
        <video
          id="videoModule"
          className="video-js vjs-default-skin"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            borderRadius: "0.25rem",
          }}
          ref={videoRef}
          playsInline
        />
      </div>
    </div>
  );
}
