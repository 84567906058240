import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { Underline } from "./Underline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function NavbarButtonDropDownButton({
  textWidth,
  buttonWidth,
  elementRef,
  text,
}) {
  const { palette } = useTheme();

  return (
    <Box style={{ textDecoration: "none" }}>
      <Underline
        palette={palette}
        width={`${textWidth * 1.65}px`}
        left={`${buttonWidth - textWidth * 1.47}px`}
      >
        <Box ref={elementRef}>
          <Typography
            sx={{
              fontWeight: 400,
              display: "flex",
            }}
          >
            {text.replace(/_/g, " ")}
            <KeyboardArrowDownIcon
              sx={{
                paddingBottom: "2px",
              }}
            />
          </Typography>
        </Box>
      </Underline>
    </Box>
  );
}
