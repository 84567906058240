import { useTheme } from "@emotion/react";
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { defaultGsapFrom, serverUrl } from "functions/values";
import React, { useEffect, useState } from "react";
import FormattedDate from "../FormattedDate";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import LoadingLogo from "components/loading/LoadingLogo";
import ReportDeletingSnackBar from "./ReportDeletingSnackBar";
import EditIcon from "@mui/icons-material/Edit";
import ReportEditingSnackbar from "./ReportEditingSnackbar";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmCancel from "../ConfirmCancel";
import usePatch from "hooks/usePatch";
import ReportTextInputs from "./ReportTextInputs";
import { useNotification } from "context/NotificationContext";
import { useReports } from "context/ReportContext";
import useFetch from "hooks/useFetch";
import { useSelector } from "react-redux";
import ReportPdfInput from "./ReportPdfInput";

export default function ReportsContainer({
  reportLanguage,
  editing,
  setEditing,
  deleting,
  setDeleting,
}) {
  const showMessage = useNotification();
  const { palette } = useTheme();
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");
  const { isPending: isPendingEdit, patchData } = usePatch(
    `${serverUrl}/reports/patch`
  );

  const reportToken = useSelector((state) => state.reportToken);
  const editReportToken = useSelector((state) => state.editReportToken);
  const token = useSelector((state) => state.token);

  const [deleteArray, setDeleteArray] = useState([]);

  const [reportsForEdit, setReportsForEdit] = useState([]); // these save reports and will overwrite the old ones on save
  const [editReport, setEditReport] = useState([]); // holds ids of the repots that are currently being edited

  const { reports, setReports, isPending } = useReports();
  const { fetchData } = useFetch();

  useEffect(() => {
    reports.length > 0 && setReportsForEdit(reports);
  }, [reports]);

  function handleChange(e, id) {
    setReportsForEdit(
      reportsForEdit.map((report) =>
        report._id === id
          ? {
              ...report,
              [(e.target.name === "reportFileGer" && "reportFileNameGer") ||
              (e.target.name === "reportFileEng" && "reportFileNameEng") ||
              e.target.name]:
                e.target.type === "file" ? e.target.files[0] : e.target.value,
            }
          : report
      )
    );
  }

  useGSAP(() => {
    if (!isPending) {
      gsap.fromTo(".reportContainer", defaultGsapFrom, {
        y: 0,
        opacity: 1,
        stagger: {
          amount: 0.5,
        },
      });
    }
  }, [isPending]);

  useGSAP(() => {
    if (deleting || editing) {
      gsap.fromTo(
        ".deleteCheckbox",
        { opacity: 0 },
        {
          opacity: 1,
          stagger: {
            amount: 0.3,
          },
        }
      );
    }
  }, [deleting, editing]);

  function animateCheckboxes() {
    gsap.to(".deleteCheckbox", {
      opacity: 0,
      ease: "power1.out",
      stagger: {
        amount: 0.5, //does not stagger for some reason
      },
    });
  }

  // loading
  if (isPending) {
    return <LoadingLogo scale={isNotMobileScreen ? 0.65 : 0.8} mt={"8rem"} />;
  }

  if (reports.length < 1) return null;

  return (
    <Box>
      {reports.map((report, i) => (
        <Box
          key={i}
          sx={{
            display: deleting || editing ? "flex" : "block",
            position: deleting || editing ? "relative" : "static",
          }}
        >
          {editReport.includes(i) ? (
            <Box className="reportContainer" width="90%">
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  const formData = new FormData();

                  const currentReport = reportsForEdit[i];

                  checkIfFileIfDeletedIfString("reportFileNameGer", "pdfGer");
                  checkIfFileIfDeletedIfString("reportFileNameEng", "pdfEng");

                  function checkIfFileIfDeletedIfString(path, name) {
                    if (currentReport[path] instanceof File) {
                      formData.append(name, currentReport[path]);
                    }
                    if (currentReport[path] === null) {
                      formData.append("onlyDelete", reports[i][path]);
                    }
                  }

                  formData.append("oldReport", JSON.stringify(reports[i]));

                  // File names
                  let formStateOnlyWithFilesNames = { ...currentReport };
                  formStateOnlyWithFilesNames.reportFileNameGer =
                    currentReport.reportFileNameGer?.name ||
                    currentReport.reportFileNameGer;
                  formStateOnlyWithFilesNames.reportFileNameEng =
                    currentReport.reportFileNameEng?.name ||
                    currentReport.reportFileNameEng;
                  // File namess

                  const formStateWithNulls = Object.fromEntries(
                    Object.entries(formStateOnlyWithFilesNames).map(
                      ([key, value]) => [
                        key,
                        value === undefined ? null : value,
                      ]
                    )
                  );

                  formData.append("report", JSON.stringify(formStateWithNulls));
                  patchData(formData, (result) => {
                    showMessage(
                      "success",
                      "Erfolgreich bearbeitet",
                      5000,
                      "left"
                    );
                    const updatedReports = reportsForEdit.map((report, index) =>
                      index === i ? result : report
                    );
                    setReports(updatedReports);

                    setEditReport(
                      editReport.filter((reportIndex) => reportIndex !== i)
                    );
                  });
                }}
              >
                <ReportTextInputs
                  report={reportsForEdit[i]}
                  handleChange={handleChange}
                />
                <Box
                  className="inputs"
                  display="flex"
                  gap="1rem"
                  m="1rem 0 2rem"
                >
                  <ReportPdfInput
                    file={reportsForEdit[i].reportFileNameGer}
                    handleChange={handleChange}
                    language={"ger"}
                    reportId={report._id}
                  />
                  <ReportPdfInput
                    file={reportsForEdit[i].reportFileNameEng}
                    handleChange={handleChange}
                    language={"eng"}
                    reportId={report._id}
                  />
                </Box>

                <ConfirmCancel
                  abortFunction={() => {
                    setEditReport(
                      editReport.filter((reportIndex) => reportIndex !== i)
                    );
                  }}
                  loading={isPendingEdit}
                />
              </form>
              <Divider sx={{ m: "1rem 0" }} />
            </Box>
          ) : (
            <Box className="reportContainer projectContainer" width="100%">
              <Typography
                style={{
                  color: palette.primary.main,
                  width: "fit-content",
                  cursor: "pointer",
                }}
                className="underline"
                variant="h3"
                onClick={() => {
                  if (reportLanguage === "ger") {
                    if (!report.reportFileNameGer) {
                      return showMessage("info", "Keine Datei angefügt");
                    }
                  }
                  if (reportLanguage === "eng") {
                    if (!report.reportFileNameEng) {
                      return showMessage("info", "Keine Datei angefügt");
                    }
                  }

                  const windowReference = window.open();

                  fetchData(
                    `${serverUrl}/reports/${
                      reportLanguage === "ger"
                        ? report.reportFileNameGer
                        : report.reportFileNameEng
                    }`,
                    editReportToken || reportToken || token,
                    (result) => {
                      if (windowReference) {
                        windowReference.location = result;
                      }
                    }
                  );
                }}
              >
                {reportLanguage === "ger"
                  ? report.reportTitleGer
                  : report.reportTitleEng}
              </Typography>

              <Typography sx={{ width: "100%" /*textAlign: "justify"*/ }}>
                {reportLanguage === "ger"
                  ? report.reportDescriptionGer
                  : report.reportDescriptionEng}
              </Typography>
              <FormattedDate date={report.createdAt} text={""} />
              <Divider sx={{ m: "1rem 0" }} />
            </Box>
          )}

          {deleting && (
            <Checkbox
              className="deleteCheckbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setDeleteArray((prev) => [...prev, report._id]);
                } else {
                  setDeleteArray((prev) =>
                    prev.filter((item) => item !== report._id)
                  );
                }
              }}
              sx={{ position: "absolute", right: 0 }}
            />
          )}
          {editing && (
            <>
              {editReport.includes(i) ? (
                <IconButton
                  color="primary"
                  className="deleteCheckbox"
                  sx={{ position: "absolute", right: 0 }}
                  onClick={() =>
                    setEditReport(
                      editReport.filter((reportIndex) => reportIndex !== i)
                    )
                  }
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  className="deleteCheckbox"
                  sx={{ position: "absolute", right: 0 }}
                  onClick={() => setEditReport([...editReport, i])}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </Box>
      ))}
      {!isPending && (
        <Typography
          className="reportContainer"
          mt="3rem"
          color={palette.neutral.main}
        >
          Für alle Berichte keine Haftung
        </Typography>
      )}

      {editing && (
        <ReportEditingSnackbar
          edting={editing}
          setEditing={setEditing}
          animateCheckboxes={animateCheckboxes}
          setEditReport={setEditReport}
        />
      )}

      {deleting && (
        <ReportDeletingSnackBar
          deleting={deleting}
          setDeleting={setDeleting}
          deleteArray={deleteArray}
          setDeleteArray={setDeleteArray}
          animateCheckboxes={animateCheckboxes}
        />
      )}
    </Box>
  );
}
