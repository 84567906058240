import { Typography } from "@mui/material";
import React from "react";

export default function ErrorComponent({ errorMessage = "" }) {
  // if error message sent an error report to server, make an admin panel.

  return (
    <Typography color="error">
      Ein Fehler ist aufgetreten und die Seite konnte nicht abgerufen werden.
      {/* Der Fehler wurde uns anonym übermittelt. */}
      {errorMessage && ` Fehlermeldung: ${errorMessage}.`}
    </Typography>
  );
}
